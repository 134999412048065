import { useTranslations } from 'next-intl';

import { useFeatureFlags } from 'app/hooks/useFeatureFlag';
import NavigationDivider from '../root-layout/Items/NavigationDivider';
import NavigationItem from '../root-layout/Items/NavigationItem';
import NavigationTitle from '../root-layout/Items/NavigationTitle';

type SidebarProps = {
  activeSection: string;
  onActiveSectionChange: (section: string) => void;
};

const Sidebar = ({ activeSection, onActiveSectionChange }: SidebarProps) => {
  const t = useTranslations();
  const featureFlags = useFeatureFlags();

  return (
    <div className="flex flex-col shrink-0 border-r border-gray-100 bg-gray-50 px-4">
      <div className="py-6 px-2 text-sm font-medium text-gray-800">
        {t('SettingsModal.settings')}
      </div>
      <nav className="flex-1">
        {/* <div> */}
        <NavigationTitle>{t('SettingsModal.personal')}</NavigationTitle>
        <NavigationItem
          onClick={() => onActiveSectionChange('Account')}
          iconSource="hero"
          iconName="UserIcon"
          active={activeSection === 'account'}
          locked={true}
        >
          {t('SettingsModal.account')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('preferences')}
          iconSource="hero"
          iconName="AdjustmentsVerticalIcon"
          active={activeSection === 'preferences'}
        >
          {t('SettingsModal.preferences')}
        </NavigationItem>
        <NavigationDivider />
        <NavigationTitle>{t('SettingsModal.company')}</NavigationTitle>
        <NavigationItem
          onClick={() => onActiveSectionChange('general')}
          iconSource="hero"
          iconName="Cog8ToothIcon"
          active={activeSection === 'general'}
          locked={true}
        >
          {t('SettingsModal.general')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('teammates')}
          iconSource="hero"
          iconName="UsersIcon"
          active={activeSection === 'teammates'}
        >
          {t('SettingsModal.teammates')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('reductionTargets')}
          iconSource="hero"
          iconName="RocketLaunchIcon"
          active={activeSection === 'reductionTargets'}
        >
          {t('SettingsModal.reductionTargets')}
        </NavigationItem>
        <NavigationItem
          onClick={() => onActiveSectionChange('modeling')}
          iconSource="hero"
          iconName="BeakerIcon"
          active={activeSection === 'modeling'}
        >
          {t('SettingsModal.modeling')}
        </NavigationItem>
      </nav>
    </div>
  );
};

export default Sidebar;
