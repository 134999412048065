import classNames from 'classnames';

import Icon, { type IconType } from '@carbonfact/ui-components/src/Icon';

type ToggleProps = {
  options: {
    label: string;
    value: string;
    icon?: IconType;
  }[];
  value?: string;
  className?: string;
  onChange?: (value: string) => void;
};
export const Toggle = ({
  options,
  value,
  onChange,
  className,
}: ToggleProps) => {
  const selectedValue = value || options[0].value;
  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };
  return (
    <div
      className={classNames(
        'flex flex-row p-[1.5px] shrink-0 rounded-md bg-carbon-100 h-9',
        className,
      )}
    >
      {options.map((option) => (
        <div key={option.value} className="flex flex-row items-center">
          <div
            className={classNames(
              'text-center cursor-pointer text-sm font-medium leading-5 tracking-tighter p-2 h-full items-center flex flex-row gap-1',
              option.value === selectedValue &&
                'bg-white shadow-toggle rounded-sm',
            )}
            role="button"
            onClick={() => handleChange(option.value)}
          >
            {option.icon && <Icon icon={option.icon} />}
            {option.label}
          </div>
        </div>
      ))}
    </div>
  );
};
