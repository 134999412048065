'use client';
import MainNavigationPanel from 'app/[locale]/(auth)/root-layout/MainNavigationPanel';
import withAuthWall from 'app/components/AuthWall';
import AutomaticIncidentBanner from 'app/components/AutomaticIncidentBanner';
import useEndpoint from 'app/lib/useEndpoint';
import { usePathname, useRouter } from 'app/navigation';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import type { ReactNode } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import Icon from '@carbonfact/ui-components/src/Icon';
import { useTranslations } from 'next-intl';
import * as React from 'react';
import SettingsActionList from './settings/SettingsActionList';

function AuthLayout({
  children,
  params,
}: {
  children?: ReactNode;
  params: Promise<{ locale: string }>;
}) {
  const { data } = useEndpoint('/settings/language');
  const router = useRouter();
  const pathname = usePathname();
  const language = data?.language;

  const { locale } = React.use(params);
  const t = useTranslations();

  const { user } = useAuth0();
  const userName = user?.name;
  const userEmail = user?.email;
  const isInternalUser = userEmail?.endsWith('@carbonfact.com');

  const { data: metadata } = useEndpoint('/metadata', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const accountName = metadata?.accountName;
  const latestSync = metadata?.latestSync
    ? dayjs(metadata.latestSync).format(
        `DD/MM [${t('SettingsActionList.at')}] HH:mm`,
      )
    : 'N/A';
  const currentEnvironment = getCurrentEnvironment();

  // If language from the DB is different from the one in localStorage, change it
  if (language && language !== locale) {
    router.replace(pathname, { locale: language });
  }
  dayjs.locale(locale);

  // This endpoint being a bit long to run, we prefer to get it once and for all
  useEndpoint('/product-modeling/config', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <div>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-gray-100 px-4">
          <div className="flex shrink-0 items-center justify-between py-6 px-2">
            <Link href="/home">
              <Image
                className="h-3 w-32"
                width={128}
                height={12}
                src="/img/carbonfact.svg"
                alt="Carbonfact"
              />
            </Link>
          </div>
          <div className="flex flex-1 flex-col">
            <nav className="flex-1">
              <MainNavigationPanel />
            </nav>
            {/* Profile dropdown */}
            {accountName &&
              (isInternalUser ? (
                <SettingsActionList
                  displayName={accountName}
                  isInternalAccount
                  environment={currentEnvironment}
                />
              ) : (
                <SettingsActionList
                  displayName={userName || accountName}
                  email={userEmail}
                />
              ))}
            {/* Footer */}
            <div className="flex flex-col gap-y-1 mb-2">
              <div className="text-left text-sm font-medium text-gray-500">
                <a
                  href="https://docs.carbonfact.com"
                  className="flex flex-row items-center gap-x-1"
                >
                  {t('SettingsActionList.documentation')}
                  <Icon
                    icon={{
                      source: 'local',
                      type: 'line',
                      name: 'external-link',
                    }}
                    width={12}
                    height={12}
                  />
                </a>
              </div>
              <div className="text-left text-xs text-gray-500">
                {t('SettingsActionList.updated')} {latestSync}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classNames('mx-auto flex flex-1 flex-col md:pl-64')}>
        <main>
          <AutomaticIncidentBanner />
          <div className="pb-6 md:pt-0 flex justify-center w-full">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default withAuthWall(AuthLayout);

function getCurrentEnvironment(): 'Live' | 'Stable' | 'Dev' {
  if (typeof window === 'undefined') {
    return 'Dev';
  }
  const hostname = window.location.hostname;
  if (hostname.includes('carbonfact.com')) {
    if (hostname.includes('live')) {
      return 'Live';
    }
    return 'Stable';
  }
  return 'Dev';
}
