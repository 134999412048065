import type { PlatformApiEndpoints } from '@carbonfact/shared/src/types/platform';
import MissingDataError from 'app/components/Error/Custom/MissingDataError';
import Loader from 'app/components/Loader';
import useEndpoint from 'app/lib/useEndpoint';
import { sortBy } from 'lodash';

type Teammate = PlatformApiEndpoints['/settings/teammates']['response'][0];

const Teammates = () => {
  const {
    data: teammates,
    isLoading,
    error,
  } = useEndpoint('/settings/teammates');

  if (error) {
    return <MissingDataError />;
  }

  if (isLoading) {
    return <Loader type="inline" />;
  }

  if (!teammates) {
    return null;
  }

  const formattedTeammates = teammates.map(formatTeammateName);
  const sortedTeammates = sortBy(formattedTeammates, 'name');

  return (
    <div className="grow overflow-y-auto max-h-[700px]">
      <div className="flex flex-col py-2 gap-4 w-full ">
        {sortedTeammates.map((teammate) => (
          <div key={teammate.email} className="flex flex-col gap-[1px]">
            <div className="font-bold">{teammate.name}</div>
            <div className="">{teammate.email}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

const formatTeammateName = (
  teammate: Teammate,
): { name: string; email: string } => {
  const firstName = teammate.firstName || '';
  const lastName = teammate.lastName || '';

  const name = `${firstName} ${lastName}`.trim() || teammate.email;
  const nameWithYou = teammate.isCurrentUser ? `${name} (You)` : name;

  return {
    name: nameWithYou,
    email: teammate.email,
  };
};

export default Teammates;
