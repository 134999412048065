import Icon, {
  type HeroIconKey,
  type IconType,
  type LocalIconKey,
} from '@carbonfact/ui-components/src/Icon';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import type { Route } from 'next';
import Link from 'next/link';

interface NavigationItemProps {
  active: boolean;
  onClick?: () => void;
  href?: Route | URL;
  children?: React.ReactNode;
  iconName: LocalIconKey | HeroIconKey;
  iconSource?: 'local' | 'hero';
  locked?: boolean;
}

export default function NavigationItem({
  active,
  href,
  onClick,
  children,
  iconName,
  iconSource,
  locked = false,
}: NavigationItemProps) {
  const activeStyle = 'bg-gray-200 font-bold';
  const lockedStyle = 'cursor-not-allowed text-gray-400';
  const className = classNames(
    'flex items-center text-sm px-2 py-[11px] font-medium gap-2 w-full rounded-md',
    active ? activeStyle : 'hover:bg-carbon-100',
    locked ? lockedStyle : 'text-gray-800',
  );

  const Content = () => (
    <div className={className}>
      <LeftIcon />
      <div className="grow shrink min-w-0">
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {children}
        </div>
      </div>
    </div>
  );

  const LeftIcon = () =>
    // If locked, render a LockClosedIcon, otherwise render an Icon component
    locked ? (
      <LockClosedIcon className="shrink-0 h-5 w-5 text-gray-400" />
    ) : (
      <Icon
        icon={
          {
            source: iconSource ?? 'local',
            name: iconName,
            type: active ? 'solid' : 'line',
          } as IconType
        }
      />
    );

  if (href) {
    return (
      <Link href={locked ? '#' : href}>
        <Content />
      </Link>
    );
  }

  if (onClick) {
    return (
      <div onClick={locked ? () => {} : onClick} className="cursor-pointer">
        <Content />
      </div>
    );
  }

  return <Content />;
}
