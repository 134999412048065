import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import type { Route } from 'next';
import Link from 'next/link';

type ActionItem = {
  type: 'action' | 'destructive';
  label: string;
  onClick?: () => void;
  href?: Route | URL;
  helpText?: string;
};

type DividerItem = {
  type: 'divider';
};

type MenuItemType = ActionItem | DividerItem;

type DropdownMenuProps = {
  items: MenuItemType[];
  className?: string;
  children: React.ReactNode;
};

const ActionList = ({ items, className, children }: DropdownMenuProps) => {
  return (
    <Menu
      as="div"
      className={classNames(
        'p-2 max-w-52 w-full relative inline-block text-left',
        className,
      )}
    >
      <MenuButton className="w-full">{children}</MenuButton>

      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 bottom-full mb-2 max-w-52 w-fit origin-bottom-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-hidden">
          <div className="px-1 py-1">
            {items.map((item: MenuItemType, index: number) => {
              switch (item.type) {
                case 'action':
                case 'destructive':
                  return (
                    <MenuItemComponent
                      key={`${item.type}-${index}`}
                      item={item}
                    />
                  );
                case 'divider':
                  return (
                    <div
                      key={`divider-${
                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                        index
                      }`}
                      className="border-t border-gray-200 my-1"
                    />
                  );
                default:
                  return null;
              }
            })}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
};

const MenuItemComponent = ({ item }: { item: ActionItem }) => {
  const baseStyle = 'group flex w-full items-center rounded-md p-2 text-sm';
  const hoverStyle = item.onClick || item.href ? 'hover:bg-gray-5' : '';
  const destructiveHoverStyle =
    item.onClick || item.href ? 'hover:bg-red-5 text-red-14' : '';

  // Combine classes with conditionals
  const className = classNames(baseStyle, {
    [hoverStyle]: item.type === 'action',
    [destructiveHoverStyle]: item.type === 'destructive',
    'cursor-pointer': item.onClick || item.href,
  });

  const Content = () => (
    <div className="flex flex-col w-full gap-1 items-start">
      <div className="shrink text-ellipsis w-full overflow-hidden text-left">
        {item.label}
      </div>
      {item.helpText && (
        <div className="shrink text-xs text-gray-500 w-full overflow-hidden text-ellipsis">
          {item.helpText}
        </div>
      )}
    </div>
  );

  if (item.href) {
    return (
      <MenuItem>
        <Link href={item.href} className={className}>
          <Content />
        </Link>
      </MenuItem>
    );
  }

  if (item.onClick) {
    return (
      <MenuItem>
        <button className={className} onClick={item.onClick}>
          <Content />
        </button>
      </MenuItem>
    );
  }

  return (
    <MenuItem>
      <div className={className}>
        <Content />
      </div>
    </MenuItem>
  );
};

export default ActionList;
