import BetaTag from 'app/components/BetaTag';
import { usePathname } from 'app/navigation';
import { useTranslations } from 'next-intl';

import AlphaTag from 'app/components/AlphaTag';
import { AccountFeatureFlag, useFeatureFlags } from 'app/hooks/useFeatureFlag';
import NavigationDivider from './Items/NavigationDivider';
import NavigationGroup from './Items/NavigationGroup';
import NavigationItem from './Items/NavigationItem';
import NavigationTitle from './Items/NavigationTitle';

function isLiveOrDevEnvironment() {
  return (
    process.env.NEXT_PUBLIC_API_BASE_URL !== 'https://stable.api.carbonfact.com'
  );
}

export default function MainNavigationPanel() {
  const t = useTranslations();
  const pathname = usePathname();
  const rootRoute = pathname.split('/')[1];

  const featureFlags = useFeatureFlags();

  return (
    <div>
      <NavigationItem
        href="/home"
        iconName="home"
        active={rootRoute === 'home'}
      >
        {t('LeftMenu.home')}
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.collect')}</NavigationTitle>
      <NavigationItem
        href="/materials"
        iconName="microchip"
        active={rootRoute === 'materials'}
      >
        {t('LeftMenu.materials')}
      </NavigationItem>
      <NavigationItem
        href="/products"
        iconName="styler"
        active={rootRoute === 'products'}
      >
        {t('LeftMenu.products')}
      </NavigationItem>
      {featureFlags.includes(AccountFeatureFlag.Factories) && (
        <NavigationItem
          href="/factories"
          iconName="factory"
          iconSource="local"
          active={pathname === '/factories'}
        >
          {t('LeftMenu.factories')}
          <BetaTag />
        </NavigationItem>
      )}
      {featureFlags.includes(AccountFeatureFlag.NewCustomRules) ? (
        <NavigationItem
          href="/custom-rules"
          iconName="ruler"
          active={rootRoute === 'custom-rules'}
        >
          {t('LeftMenu.customRules')}
          <BetaTag />
        </NavigationItem>
      ) : (
        <NavigationItem
          href="/data-augmentation-rules"
          iconName="ruler"
          active={rootRoute === 'data-augmentation-rules'}
        >
          {t('LeftMenu.dataAugmentationRules')}
        </NavigationItem>
      )}

      {featureFlags.includes(AccountFeatureFlag.CarbonAccounting) && (
        <NavigationGroup
          iconName="briefcase"
          active={rootRoute === 'corporate'}
          title={t('LeftMenu.corporate')}
        >
          <NavigationItem
            href="/corporate/facilities"
            iconName="shop"
            active={pathname === '/corporate/facilities'}
          >
            {t('LeftMenu.facilities')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/expenses"
            iconName="bank-note"
            active={pathname === '/corporate/expenses'}
          >
            {t('LeftMenu.expenses')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/transport"
            iconName="truck"
            active={pathname === '/corporate/transport'}
          >
            {t('LeftMenu.transport')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/business-travel"
            iconName="airplane"
            active={pathname === '/corporate/business-travel'}
          >
            {t('LeftMenu.businessTravel')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/commute"
            iconName="car"
            active={pathname === '/corporate/commute'}
          >
            {t('LeftMenu.commute')}
          </NavigationItem>
        </NavigationGroup>
      )}

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.measure')}</NavigationTitle>
      <NavigationItem
        href="/trends"
        iconName="line-up"
        active={rootRoute === 'trends'}
      >
        {t('LeftMenu.trends')}
      </NavigationItem>
      <NavigationItem
        href="/explorer"
        iconName="perspective"
        active={rootRoute === 'explorer'}
      >
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {t('LeftMenu.explorer')}
        </div>
      </NavigationItem>

      <NavigationItem
        href="/views"
        iconName="preset"
        active={rootRoute === 'views'}
      >
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {t('LeftMenu.views')}
        </div>
      </NavigationItem>

      {(featureFlags.includes(AccountFeatureFlag.AiChat) ||
        isLiveOrDevEnvironment()) && (
        <NavigationItem
          href="/ai-chat"
          iconName="ChatBubbleLeftEllipsisIcon"
          iconSource="hero"
          active={rootRoute === 'ai-chat'}
        >
          {t('LeftMenu.aiChat')} <AlphaTag />
        </NavigationItem>
      )}

      <NavigationDivider />

      <NavigationTitle>{t('LeftMenu.reduce')}</NavigationTitle>
      <NavigationItem
        href="/product-modeling"
        iconName="t-shirt"
        active={rootRoute === 'product-modeling'}
      >
        {t('LeftMenu.productModeling')}
      </NavigationItem>
      <NavigationItem
        href="/company-modeling"
        iconName="trolley"
        active={rootRoute === 'company-modeling'}
      >
        {t('LeftMenu.companyModeling')}
        <BetaTag />
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.report')}</NavigationTitle>
      <NavigationItem
        href="/reports"
        iconName="reports"
        active={rootRoute === 'reports'}
      >
        {t('LeftMenu.reports')}
      </NavigationItem>
    </div>
  );
}
